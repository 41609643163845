
/* utility */

.section
{
 padding: 80px 0px 80px;
}
.primarycontainer
{
   margin-left: calc((100% - 1140px) / 2);
   margin-right: 0px;
}

.secondarycontainer
{
   margin-right: calc((100% - 1140px) / 2);
   padding-left: calc((100% - 1140px) / 2);
   background-color: var(--secondary);
}


.hero_title{
    font-size: 3.4rem;
    font-weight: 900;
    line-height: 4.4rem;
    margin: 15px 0 15px;
 }
 .page_title{
    font-size: 34px;
    font-weight: 600;
    line-height: 44px;
    margin: 15px 0 15px;
 }
 .section_title{
    font-size: 22px;
    font-weight: 700;
 }
 .slider_title{
   font-family:var(--fontfamily);
  font-size: 28px;
  font-weight: 500;
  line-height:34px;
 }
 .primary_title{
    font-size: 20px;
   font-weight: 600;
 }
 .secondary_title{

   font-family:var(--fontfamily);
   font-size:20px;
   font-weight:500;
   line-height:28px;

 }
 

 .white
 {
   color: #fff;
 }
 .textAlign
 {
   text-align: center;
 }
 
 .header_title
 {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
 }
 .pb-30
 {
   padding-bottom: 30px;
 }
 .padding
 {
   padding: 40px 0px 20px;
 }

 .container{
    padding-block:20px  !important;
 }
 
 .desc_title
 {
    color: var(--secondry);
    font-size: 30px;
    font-weight: 400 !important;
 }
 .heading_title
 {
     font-size: 36px;
      font-weight: 800;
 }
 
 .primary_button
 {
    
    font-size: 18px ;
    padding: 12px 24px;
    border-radius: 4px;
    outline: none;
    border: 1px solid var(--primary);
    color:#000000;
    background-color: var(--primary);
    cursor: pointer;
     box-sizing: border-box;
     position: relative;
     font-weight: 600;
 }
 
 .button:hover{
 
    opacity: 1;
 }
 
 .image_develop
 {
    max-width: 100%;
    height: auto;
 }
 
 .fade-left
 {
    animation: leftmove 2s ;
    position: relative;
 
 }
.banner_image
{
   width:100%;
   height:100%;
   margin-top: 10px;
}
.banner_image img
{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

  @keyframes leftmove
  {
    0%{   
     right:-100px;
     opacity: 0;
    }
    100%
    {
       right:0px;
       opacity:1;
    }
  }
 
  .fade-right
 {
    animation: mymove 2s ;
    position: relative;
 
 }
  @keyframes mymove
  {
    0%{   
     left:-100px;
     opacity: 0;
    }
    100%
    {
       left:0px;
       opacity:1;
    }
  }
 
 @media (max-width: 768px) {
   
    .page_title {
        font-size: 24px;
        line-height: 30px;
    }
    .section_title{
        font-size: 20px;
    }
    .slider_title{
      font-size: 24px;
    }
    .primary_title{
        font-size: 18px;
     
    }
    .secend_title{
        font-size: 14px;
        line-height: 18px;
    } 
 
    .hero_title
    {
       font-size: 40px;
       font-weight: 900;
       line-height: 50px;
    }
    .desc_title
    {
       font-size: 24px;
    }
 
    .primary_button
    {
       font-size: 16px ;
       padding: 10px 20px;
    }
    .heading_title
    {
       font-size: 30px;
    }


   .padding
   {
     padding: 0px 0px 20px;
   }

  
   .section
   {
      padding: 40px 0px 40px;
   }
 }

 .home_font{
 
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
 }


 
 /* Section 1 start */
 
 .contact
 {
    line-height: 27px;
    font-size: 20px;
 }
 
 .query
 {
    line-height: 26px;
    font-size: 17px;
    color: var(--secondry);
    font-weight: 500;
 }
 
 @media (max-width:1000px)
 {
    .concern
    {
       display: grid;
       width:100vw;
    }
 }
 
 /* Section 2 Start */
 
.backgroundheight
{
   background-color:var(--primary);
   height:400px;
   padding:20px;
} 

.backgroundsecondaryheight
{
   background-color:var(--secondary);
   height:400px;
   padding:20px;
} 
.ul{
   display:grid !important;
   grid-row-gap:6px !important;
   margin-left: 0px !important;
   padding-left: 20px;
}
.brand 
{
   display: flex !important;
   flex-direction: column !important;
   grid-row-gap: 20px !important;
}

.farmer-gap
{
   display: flex !important;
   flex-direction: column !important;
   grid-row-gap: 8px !important;
}


.branded
{
   display: flex !important;
   flex-direction: column !important;
   grid-row-gap: 30px !important;
}


@media (max-width:768px)
{
   .backgroundsecondaryheight
   {
      height: 360px;
   }
   .backgroundheight
   {
      height: 360px;
   }
}


 
 /* Section 2 End */
 
 /* Section 3 Start */
 
 .description
 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
 }

 .youtubeimage
 {
   width:160px;
 }
 .youtubemobileimage
 {
   width:120px
 }
 
 .choose
 {
   font-size: 1.25rem;
   line-height: 1.75rem;
   color:var(--primary);
   font-weight: 550;
   margin-bottom: 1.25rem;
 }
 
 .about1
 {
   max-width: 100%;
   height: auto;
   margin-bottom: 2rem;
 }
 .about2
 {
   max-width: 100%;
   height: auto;
 }
 .about3
 {
   max-width: 100%;
   height: auto;
   display: block;
   margin-bottom: 1.25rem;
   margin-top: 1.875rem;
 }
 
 .faderight
 {
    animation: mymove 2s ;
    position: relative;
 
 }
.uxImage
{
   display: flex;
   align-items: center;
   background-color: #fff;
   height: 80vh;
}

  @keyframes mymove
  {
    0%{   
     left:-100px;
     opacity: 0;
    }
    100%
    {
       left:0px;
       opacity:1;
    }
  }
 
 
 
  .fadeleft
 {
    animation: leftmove 2s ;
    position: relative;
 
 }
  @keyframes leftmove
  {
    0%{   
     right:-100px;
     opacity: 0;
    }
    100%
    {
       right:0px;
       opacity:1;
    }
  }
 
 
  @media (max-width:768px)
  {
     .faderight
     {
      display: none;
     }
     .fadeleft{
        width: 214%;
        left: -105%;
        padding:  0px 20px;
        box-sizing: border-box;
     }
     a{
      font-size: 14px;
     }
     .description
     {
      font-size: 14px;
     }
     .uxImage
     {
      height: auto;
     }
  }
  @media (max-width:600px)
  {
     .fadeleft
     {
        width: 100%;
        left: 0px;
        padding:  0px 0px;
     }
  }
 
  @media (max-width:998px)
  {
    .wireless_gadget
    {
       width:47vw;
    }
  }
 
  @media (max-width:769px)
  {
    .wireless_gadget
    {
       width:87vw;
    }
  }
  
 /* Section 3 End */
 
 
 /* Section 4 start */
 
 .offers
 {
      text-align: center;
      padding-bottom: 3%;
 }
 
 .curElem
 {
    background-color: var(--white);
    margin:15px 0px 15px;
    height: auto;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
 }
   .main
   {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
    align-items: center;
   }
   .title{
    font-size: 18px;
    font-weight: 500;
    font-family: var(--font-family);
   }
   .desc
   {
    font-size: 16px !important;
    padding: 0px 60px 0px 10px;
    line-height: 24px;
    position: relative;
    top:-22px;
    font-weight: 400;
   }
 
 .addicon:hover
 {
     background-color: var(--primary);
     border-radius: 50%;
 }

 .duration
 {
   display: flex;
   align-items: flex-start;
  grid-column-gap: 30px;
 }
 .duration .label {
   width: 120px;
   color: var(--secondary);
   font-weight: 600;
   display: flex;
   align-items: center;
 }
 .label_data{
   color: var(--secondary);
   font-weight: 600 !important;

 }

.eastIcon
{
    transform: scale(0.8);
    display: flex;
    align-items: center;
    padding-right: 6px;
}

 .duration .labels
 {
    width:100px;
    font-weight: 550;
 }

 .demand
{
   display: flex !important;
   flex-direction: column !important;
   grid-row-gap: 36px !important;
}
.gray 
{
   color: #60727c;
  
}
 
 
 /* Section 4 End */
 
 
 /* Section 5 Start */
 
 .glimpseimage
 {
    max-width:100%;
    cursor: pointer;
 }
 
 .glimpseimage img 
 {
 
    width: 100%;
    aspect-ratio: 1.5;
    object-fit: contain; 
 }
 .glimpseimage:hover
 {
   box-shadow:0px 0px 8px 2px  rgba(0,0,0,0.12);
 }


 .report
 {
     padding:40px 0% 40px;
     width:48px;
 }
 .service_flow
 {
   
    padding: 5% 5% 5%;
    border-radius: 10px;
    height:280px;
 
 }
 
 .service_flow:hover
 {
    box-shadow: 0px 0px 2px 0px  white;
    background-color:antiquewhite;
 
 }
 .describe
 {
    font-size: 16px;
    line-height: 24px;
    position: relative;
    font-weight: 400;
    font-family: var(--font-family);
 }
 .describes
 {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    font-weight: 400;
    font-family: var(--font-family);
    padding-bottom: 26px;
 }

 .blog_category_chips
 {

    background-color: #151515;
    color: #ffffff;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 12px;
    font-family: var(--font-family);
    width: fit-content;
    font-weight: 500;
    cursor: pointer;
 }

 .blog_category_chips-not-selected
 {

    background-color: var(--primary);
    color: #000000;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 12px;
    font-family: var(--font-family);
    width: fit-content;
    font-weight: 550;
    cursor: pointer;
 }

 

 @media (max-width:768px)
 {
    .service_flow
    {
       height: 350px;
    }
    .title{
      font-size: 16px;
    }
 }
 
 @media (max-width:900px)
 {
    .service_flow
    {
       padding: 5% 8% 5%;
    }
 }
 
 
 
 /* Section 5 End */
 
 /* Section 6 Start */
 
 .adidhiimage
 {
  max-width: 120px;
 }
 
 .adidhiimage img
 {
   width:100%;
   height: 100%;
   object-fit: contain;
 }
 
 @media (max-width:768px)
 {

   .adidhiimage
   {
     max-width: 100px;
  
   }
   .adidhiimage img
   {
     width:100%;
     height:auto;
     aspect-ratio:0;
     object-fit: contain;

   }

  

 }
 
 /* Section 6 End */
 
 /* Section 7 start */
 
 .transportation
 {
     background-color: #fff;
     line-height:normal !important;
     border-radius: 10px;
     border: 1px solid #dbdada !important;
     cursor: pointer;
     box-sizing: border-box;
     height: 100%;
     position: relative;
 }
 .react-loading-skeleton
 {
   line-height: normal !important;
 }

 .transportation:hover
 {
   transform: scale(1.02);
   box-shadow: 0px 0px 12px -4px rgb(132 132 137);
 }
 
 .sar{
   display: grid;
   grid-row-gap: 6px;
   padding: 20px;
   box-sizing: border-box;
 }
 .sarskeleton
 {
   display: grid;
   grid-row-gap: 14px;
   padding: 20px 20px 40px;
   box-sizing: border-box;
 }
 .communication
 {
      padding: 5% 0% 5% !important;
      box-sizing: border-box;
 }
 
.abstracts
{
     width: 100%;
     height: 220px;
     object-fit: contain;
     background-color: #e9e8e8;
     border-radius: 10px 10px 0px 0px;
     box-sizing: border-box;
}

.abstracts img 
{
   width:100%;
   height:auto;
}


.abstracts img 
{
   width: 100%;
   height:auto;
   border-radius: 10px 10px 0px 0px;
   box-sizing: border-box;
}

 .abstract
 {
   
   width: 100%;
   height: 240px;
   object-fit:inherit;
     border-radius: 10px 10px 0px 0px;
     box-sizing: border-box;

 }

.expand
{
   text-decoration: none !important;
}
 .expand:hover
{
    text-decoration:underline solid #151515 !important;
    text-underline-offset: 10px !important;
}
 .expand_all:hover 
 {
    background-color: transparent !important;
 }
 
 
 
 /* Section 7 end */
 
 /* Section 8 Start */
 
 .form
 {
    background-color: #FFFCEC;
     box-shadow: -1px 4px 20px -6px rgb(132 132 137);
     padding:40px;
 }
 
 .label-form
 {
  
        color:var(--black);
        font-weight: 550;
        font-size: 16px;
        padding-bottom: 10px;
    
 }
 .input-form
 {
   
    border-radius: 8px;
    padding-left: 10px;
    font-size: 16px;
 }
 .select-form
 {
    height:60px;
    border:1px solid var(--primary);
    border-radius: 8px;
    padding-left: 10px;
    font-size: 16px;
 }
 
 .read-more
 {
     border: 1px solid #18143B ;
     width: fit-content;
     padding: 4px 8px;
     margin-bottom: 20px;
     font-size: 16px;
     font-family: var(--font-family);
     font-weight: 600;
     cursor: pointer;
     margin-top: 4px;
     border-radius: 4px;
 }
 .read-more:hover
 {
    background-color:var(--secondary);
    color: #ffffff;
 }
 .MuiBreadcrumbs-separator 
 {
   color: #fafafa !important;
 }
 
 .textarea
 {
    border:1px solid var(--primary);
    border-radius: 8px;
    padding:20px ;
    line-height: 26px;
    font-size: 18px;
 }
 .form_title
 {
    display: grid;
    grid-row-gap: 5px;
 }
 
 .submit-button
 {
    display: flex;
    justify-content: center;
    padding: 3% 0px 0px;
 }
 .error
 {
    font-size:14px;
    color:red;
 }
 
 @media (max-width:768px)
 {
    .form{
       padding: 6% 6% 3%;
    }
    .label-form
    {
       font-size: 14px;
    }
    .input-form
    {
       font-size: 14px;
    }
 }
 @media (max-width:600px)
 {
    .form{
       padding: 6% 14% 3%;
    }
 }
 /* Section 8 End */

 /*header*/
 .header
{
    background-color: var(--secondary);
    height:80px;
    display: flex;
    position: sticky;
    top: 0;
    width:100%;
    z-index:999;
    align-items: center;
    box-sizing: border-box;
}
.home
{
     text-decoration: none;
}


.homehover:hover {

   color:var(--primary);
   text-decoration: underline solid var(--primary) !important; 
   text-underline-offset: 4px;
 }

.uxhover
{
   color:var(--secondary);
}
.uxhoverdata
{
   color:var(--secondary);
}

.uxhoverdata:hover
{
   color:var(--secondary);
   text-decoration: underline solid var(--secondary) !important; 
   text-underline-offset: 4px;
}

.uxhover:hover
{
   color:var(--secondary);
   text-decoration: underline solid var(--secondary) !important; 
   text-underline-offset: 2px;
}

.uihover:hover
{
   color:#fafafa;
   text-decoration: underline solid #fafafa !important; 
   text-underline-offset: 2px;
}

.uxhover:hover
{
   color:var(--secondary);
   text-decoration: underline solid var(--secondary) !important; 
   text-underline-offset: 2px;
}

.closeicon
{
     display: flex;
     justify-content: end;
     position: relative;
     box-sizing: border-box;
     cursor: pointer;
    border-radius: 50%;
    padding: 15px 20px 0px;

}
.close-icon:hover
{
    background-color: var(--primary);
    position: relative;
    border-radius: 50%;
}
.logo-img
{
    max-width:120px;
    cursor: pointer;
}
.whatsapp-img
{
   max-width: 60px;
}
.nav_bar
{
    position: relative;
    top:-40px;
}
.list_item
{
    padding-top: 16px;
}
.profile_setting
{
    display: flex !important;
    grid-column-gap: 6px !important;
    align-items: center;
    
}
.aboutimage
{
   width: 100%;
   height: calc(100vh - 80px);    
}
.image_container
{
   max-width: 100%;
   height: calc(100vh - 80px);
}

.image_containers
{
   max-width: 100%;
}

.image_containers img 
{
   
   width: 100%;
   height: 100%;
   aspect-ratio: 1.5;
   object-fit: contain;
   
}
.image_contains 
{
   max-width: 100%;
   height:100%;
   aspect-ratio: 1.2;

}


.brandimage_containers
{
   max-width: 100%;
   height: calc(100vh - 150px);
   display: flex;
   align-items: center;
}
.brandimage_containers img 
{
   height: calc(100vh - 150px);
   object-fit: contain;
   aspect-ratio: 1;
}


.menuitem
{
    min-height: fit-content !important;
    padding-top: 0px !important;
}
.navlink
{

   display: flex;
   grid-column-gap: 30px;
   align-items: center;
   font-weight: 500;
  font-size: 14px;
} 

.mobile_link
{
    display:none;
}

.hamburger
{
    position: relative;
    right: 25px;
}

.list_item
{
    font-size: 18px;
    font-weight: 700;
    margin: 8px 0px 8px 8px;
}

@media (max-width:768px)
{
    .navlink{
        display: none;
    }
    .aboutimage
    {
      height: auto;
    }
    .image_containers img 
    {
      padding-block: 0px;
    }
.brandimage_containers
{
   max-width: 100%;
   height: auto;
}

.brandimage_containers img 
{
    width: 100%;
    height: auto;
    aspect-ratio: 0;
}
.image_contains
{
      width: 100%;
    height: auto;
    aspect-ratio: 0;
}

}

@media (max-width:600px)
{
    .mobile_link
    {
        display: flex;
        background: transparent;
        outline: none;
        border: none;
        position: relative;

    }
}


 /*About*/
 .aboutus
{
    transform:  rotate(-180deg)
}

.desktop_view {
    display: block;
}
.header_shap {
    position: absolute;
    left: 0;
    right: 0;
    bottom:0;
}
svg {
    overflow: hidden;
    vertical-align: middle;
}
.commitment
{
    background-color: var(--primary);
    text-align: center;
    position: relative;
    top:-12px;
    padding: 10px 0px;
}
/* Section 1 Start */
.about_image
{
    max-width: 100%;
   height: auto;
}
.contentstory
{
    margin-top: 3%;
}

/* Section 1 End */

/* Section 2 Start */
 
.radifix_image
{
    width:100%
}

.protection
{
    margin-top: -5%;
}

/* Section 2 End */

/* Section 3 Start */
.technology
{
    display: flex;
}

.paragraph
{
     padding-left: 30px;
     position: relative;
     top:-20px;
}
.offers_health
  {
       text-align: center;
       line-height: 26px;
  }
 
 .desc_back
 {
     background-color: var(--white);
     box-shadow: -1px 2px 10px -6px rgb(132 132 137);
     height:96%;
     box-sizing: border-box;
 } 

 .desc_title
 {
    padding: 50px 30px;
    box-sizing: border-box;
 }

 .member_image
 {
     border: 5px solid var(--primary);
 }

 .image_name
 {
    font-size: 16px;
    line-height: 23px;
 }
 @media (max-width:996px)
 {
    .member_image{
        width:150px;
        height:"auto";
    }
   
 }

@media (max-width:768px)
{
     .technology
     {
        display: grid;
     }
     .paragraph
     {
         padding-left: 8px;
         top:0px;
     }
}
/* Section 3 End */

/*Services */

.msgimage
{
   width:80px;
}
.ellipsesimage
{
   width:50px;
}
.ellipsesimages
{
   width:80px;
}
.faceimages
{
   width:40px;
   position: absolute;
   top: 48%;
   left: 50%;
   transform: translate(-50%, -52%);
}

.faceimage
{
   width:28px;
   position: absolute;
   top: 48%;
   left: 50%;
   transform: translate(-50%, -52%);
   
}

.aidimage
{
   width: 50px;
   display: flex;
   justify-content: center;
}

.niches
{
     display: flex;
     grid-row-gap: 22px;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.project_form
{
   margin: 10px 0px 30px;
   text-align: center;
   display: flex;
   flex-direction: column;
   grid-row-gap: 16px;

}
.branding
{
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-template-rows: 1fr 1fr 1fr;
   grid-gap: 40px;

}
.flex
{
   display: flex;
   align-items: center;
   grid-column-gap: 20px;
}
.messagingimage
{
   width:60px
}

.webimage
{
   width:60px;
}
.brandimage
{
   width:28px;
   position: absolute;
   top: 48%;
   left: 50%;
   transform: translate(-50%, -52%);
   
}


.testimonial-slide {
   margin-right: 40px; 
   width: fit-content !important;
   padding: 60px 0px !important;
   display: flex !important;
   grid-column-gap: 30px !important;
 }
 
 
 .testimonial-slide.last-slide {
   margin-right: 0;
 }

 .testimonial_image
 {
   border-radius: 10px;
   background: #FFFDF3;
   padding: 20px;
   max-width: 100%;
   height: fit-content;
 }

 .testimonial_autor
 {
    display: flex;
    justify-content: flex-end;
    padding: 30px;
 }


@media (max-width:768px)
{
   .branding
{
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: 1fr 1fr 1fr 1fr;
   grid-gap: 40px;

}
      .aidimage
      {
         width: 50px;
         display: flex;
         justify-content: center;
      }

       .ellipsesimages
      {
         width:50px;
      }
      .faceimages
      {
         width:28px;
         position: absolute;
         top: 48%;
         left: 50%;
         transform: translate(-50%, -52%);
      }
         .msgimage
         {
            width:50px;
         }

         .testimonial-slide {
            margin-right: 0px; 
            width:100% !important;
            padding: 30px 0px !important;
          }
       
          .testimonial_autor
          {
             display: flex;
             justify-content: flex-end;
             padding: 20px;
          }

}

 /*Contact*/
 .contact_us
{
    background-color:#18143B;
    margin-top: -20px;
}

.join_us
{
    padding-bottom: 5%;
}
 

/*Footer */
.footer_title
{
    color:var(--secondry);
    text-decoration: none;
    line-height:normal;
}
.footer_pagargraph
{
  
    font-family: var(--font-family);
    display: grid;
    grid-row-gap: 6px;
    line-height: 10px;
}
.footer_primary_title
{
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.footer_address
{
    line-height: 24px;
    padding: 8px 0px 6px;
}
.footer_font
{
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family);
}
.footer_fonts
{
    font-size: 12px;
    font-weight: 500;
    font-family: var(--font-family);
}
.phone_icon
{
    display: flex;
    color:var(--secondry);
    text-decoration: none;
    line-height:normal;
    grid-column-gap: 2px;
    align-items: center;

}
.contact_radifix
{
    color:var(--secondry);
    text-decoration: none;
    font-size: 16px;
}
.border_top
{
   margin-top: 10px;
   border-top: 1px solid #D3C1C1;
   padding: 20px;
}

.footer_link
{
    color: black;
    text-decoration: none;
}
.footer_condition
{
   display: flex;
   grid-column-gap: 24px;
   box-sizing: border-box;
   flex-wrap: wrap;
   grid-row-gap: 8px;
  
}

.footer_terms
{
   display: flex;
   justify-content: space-between;
   align-items: center;
   grid-column-gap: 20px;
   grid-row-gap: 8px;
   flex-wrap: wrap;
}

.black{
   color: #000000 !important;
}
.social_media
{
   display: flex;
   grid-column-gap: 20px;
   flex-wrap: wrap;
   grid-row-gap: 10px;
   padding-top: 14px;
  
}


@media (max-width:768px)
{
   .footer_condition
{
   display: flex;
   grid-column-gap: 24px;
   box-sizing: border-box;
   flex-wrap: wrap;
   grid-row-gap: 0px;
  
}
   
}

/*Terms & Conditions */
.text-center
{
   text-align: center !important;
}

.semibold
{
   font-weight: 600;
}
.mb-40
{
   margin-bottom: 40px !important;
}
.p-0 
{
   padding: 0px !important;
}
.m-0
{
   margin: 0px !important;
}
.mt-20
{
   margin-top: 20px !important;
}
.line-height
{
   line-height:24px !important;
}


.mt-10{
   margin-top: 10px !important;
}
.mt-40
{
   margin-top: 40px !important;
}

/* Shipping */

.bold 
{
   font-weight: 700;
}

.mt-2
{
    margin-top: 2px;
}
.mb-2{
   margin-bottom: 2px;
}
.mt-1 
{
   margin-top: 1px;
}
.mb-1
{
   margin-bottom: 1px;
}
.box-shadow
{
  box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125) !important;
  border: 1px solid #f2f2f2;
}

.common-box
{
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   width: 100%;
   grid-gap: 20px;
   padding: 30px;
   margin: 60px 0px 0px; 
}


/*Vision*/
.font_30
{
   font-size: 30px;
}

.width-80
{
   width:80%;
   line-height: 40px;
}
.pb-70
{
   padding-bottom: 70px;
}
.superheroes
{
   display: flex;
   flex-direction: column;
   align-items: center;
}


@media (max-width:768px)
{
   .font_30{
      font-size: 26px;
   }
   .pb-70{
        padding-bottom: 40px;
   }
   .width-80
   {
      width: 100%;
   }
}

/* Wordpress */

.premium
{
   background: #FFFFFF;
   border: 2px solid #FFFFFF;
   box-sizing: border-box;
   box-shadow: 0px 12px 60px 7px rgba(0, 0, 0, 0.08);
   border-radius: 10px;
   margin:auto 20px;
   position:relative;
   height:100%;
   padding: 10px 20px 20px;
}

.badge 
{
   background-color: #FFE95A;
   font-family: var(--font-family);
   font-style: normal;
   font-weight: 500;
   font-size: 12px;
   line-height: auto;
   text-align: center;
   letter-spacing: 0.02em;
   text-transform: capitalize;
   color: #18143B;
   float: right;
   position:absolute;
   right:-17px;
   padding:8px 12px;
   border-radius:10px 0 0 10px;
   z-index: 2;
 
  
}

.badges
{
    
   position: absolute;
    background:url("/public/corner.png");
    background-repeat: no-repeat;
    background-size:contain;
    width:16px;
    height:14px;
    top:40px;
    right:-18px;
 
  
}

.dynamic
{
   padding:30px 0px 20px;
   position:relative;
}
.dynamic_label 
{
      color: #9D9D9D;
      font-family: var(--font-family);
      font-size: 16px;
      font-weight: 500;
      line-height: 18px; 

}
.item-list
{
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    margin: 0px !important;
}

.keyboardArrow
{
   opacity: 0.4;
   cursor: pointer;
}
.border-radius
{
   border-radius: 10px;
}
.border-bottom
{
   border-bottom: 1px solid rgb(221, 216, 216);
}

.p-20{
   padding: 20px;
}
.developcarousel div
{

   margin-right: 20px;
}


/*uxdevelopment */
.upper_circle
{
    position: absolute;
    z-index: -1;
    top:-10px;
   
}
.lower_circle
{
   position: absolute;
   top:-10px;
   right: -10px;
   z-index: -1;
}

.ux-services
{
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
    align-items: center;
}

.background-ui_ux
{
    background-color: #FFFCEC;
    padding:24px;
    border-radius:100%;
    box-sizing: border-box;
    width: fit-content;
    display: flex;
    justify-content: center;
   
}

/*glimpsesUI*/
.glimpsesUI div{
   width: 95%;
}

.MuiOutlinedInput-input
{
   background-color: #ffffff !important;
}
.MuiDialog-paper
{
    min-width: 440px;
}
.MuiDialogContentText-root
{
   font-size: 14px !important;
   font-family: var(--font-family) !important;
   font-weight: 500 !important;
}

@media (max-width:768px) {

   .MuiDialog-paper
{
    min-width: 60%;
}
}

/*Whitpaper*/
.ux_ui_design
{
    
    width: 44%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    height:auto;
    position: absolute;
    border-radius: 12px;
    justify-content: center;
    padding-top: 10px;
    
    
}
.edit_data
{
   display: flex;
   justify-content: space-between;
   align-items: center;
   grid-column-gap: 40px;
}

.form_data
{
   display: flex;
   justify-content: space-between;

   width: 100%;
}

/*Blog*/
.similar_blog_image
{
   width:112px;
   height:92px;
   border-radius: 6px;

}

.similar_blog
{
   display: flex;
   grid-column-gap: 30px;
   align-items: center;
   cursor: pointer;
}
.similar_blog_title
{
   font-size: 16px;
   font-weight: 600;
   font-family: var(--font-family);
}

.sharelink
{
 
    display: flex;
    grid-column-gap: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    align-items: center;
}

@media (max-width:768px) {
   .sharelink 
   {
      display: grid;
      grid-row-gap: 10px;
   }
   
}






